import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _5cab120d = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _a94c3090 = () => interopDefault(import('../pages/autoconnect.vue' /* webpackChunkName: "pages/autoconnect" */))
const _49e91f8d = () => interopDefault(import('../pages/marques.vue' /* webpackChunkName: "pages/marques" */))
const _61ef379c = () => interopDefault(import('../pages/-artists.vue' /* webpackChunkName: "" */))
const _d3903906 = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _f7ef60f4 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _10b20a8e = () => interopDefault(import('../pages/-maintenance.vue' /* webpackChunkName: "" */))
const _10fa3f2e = () => interopDefault(import('../pages/unsubscribe.vue' /* webpackChunkName: "pages/unsubscribe" */))
const _54cb0c90 = () => interopDefault(import('../pages/wishlist.vue' /* webpackChunkName: "pages/wishlist" */))
const _4469620c = () => interopDefault(import('../pages/account/address-book/index.vue' /* webpackChunkName: "pages/account/address-book/index" */))
const _703d3141 = () => interopDefault(import('../pages/account/card-book.vue' /* webpackChunkName: "pages/account/card-book" */))
const _43782f84 = () => interopDefault(import('../pages/account/giftcard-activation.vue' /* webpackChunkName: "pages/account/giftcard-activation" */))
const _e776815c = () => interopDefault(import('../pages/account/history/index.vue' /* webpackChunkName: "pages/account/history/index" */))
const _5703430f = () => interopDefault(import('../pages/account/modify.vue' /* webpackChunkName: "pages/account/modify" */))
const _787cacc9 = () => interopDefault(import('../pages/account/modify-id.vue' /* webpackChunkName: "pages/account/modify-id" */))
const _189aa19e = () => interopDefault(import('../pages/account/subscriptions.vue' /* webpackChunkName: "pages/account/subscriptions" */))
const _79fccd29 = () => interopDefault(import('../pages/account/voucher.vue' /* webpackChunkName: "pages/account/voucher" */))
const _d2572b00 = () => interopDefault(import('../pages/checkout/add_address.vue' /* webpackChunkName: "pages/checkout/add_address" */))
const _2fb93d8c = () => interopDefault(import('../pages/checkout/basket.vue' /* webpackChunkName: "pages/checkout/basket" */))
const _c1bcca2c = () => interopDefault(import('../pages/checkout/confirm.vue' /* webpackChunkName: "pages/checkout/confirm" */))
const _2ec9d330 = () => interopDefault(import('../pages/checkout/payment.vue' /* webpackChunkName: "pages/checkout/payment" */))
const _9a204c18 = () => interopDefault(import('../pages/checkout/shipping.vue' /* webpackChunkName: "pages/checkout/shipping" */))
const _8b3ea722 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _5016703c = () => interopDefault(import('../pages/account/address-book/_id.vue' /* webpackChunkName: "pages/account/address-book/_id" */))
const _6dac3d86 = () => interopDefault(import('../pages/account/history/_id/index.vue' /* webpackChunkName: "pages/account/history/_id/index" */))
const _bfc28e44 = () => interopDefault(import('../pages/account/history/_id/product-return-detail/index.vue' /* webpackChunkName: "pages/account/history/_id/product-return-detail/index" */))
const _a923b514 = () => interopDefault(import('../pages/account/history/_id/product-return-detail/_idreturn.vue' /* webpackChunkName: "pages/account/history/_id/product-return-detail/_idreturn" */))
const _3ad2a9ad = () => interopDefault(import('../pages/search/_query.vue' /* webpackChunkName: "pages/search/_query" */))
const _57ff01ec = () => interopDefault(import('../pages/t/_name.vue' /* webpackChunkName: "pages/t/_name" */))
const _5746f5ec = () => interopDefault(import('../pages/tp/_name.vue' /* webpackChunkName: "pages/tp/_name" */))
const _25c7645d = () => interopDefault(import('../pages/p/_.vue' /* webpackChunkName: "pages/p/_" */))
const _73468008 = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _5cab120d,
    pathToRegexpOptions: {"strict":true},
    name: "account___fr"
  }, {
    path: "/account",
    component: _5cab120d,
    pathToRegexpOptions: {"strict":true},
    name: "account___es"
  }, {
    path: "/account",
    component: _5cab120d,
    pathToRegexpOptions: {"strict":true},
    name: "account___it"
  }, {
    path: "/account",
    component: _5cab120d,
    pathToRegexpOptions: {"strict":true},
    name: "account___nl"
  }, {
    path: "/account",
    component: _5cab120d,
    pathToRegexpOptions: {"strict":true},
    name: "account___de"
  }, {
    path: "/account",
    component: _5cab120d,
    pathToRegexpOptions: {"strict":true},
    name: "account___en"
  }, {
    path: "/autoconnect",
    component: _a94c3090,
    pathToRegexpOptions: {"strict":true},
    name: "autoconnect___fr"
  }, {
    path: "/autoconnect",
    component: _a94c3090,
    pathToRegexpOptions: {"strict":true},
    name: "autoconnect___es"
  }, {
    path: "/autoconnect",
    component: _a94c3090,
    pathToRegexpOptions: {"strict":true},
    name: "autoconnect___it"
  }, {
    path: "/autoconnect",
    component: _a94c3090,
    pathToRegexpOptions: {"strict":true},
    name: "autoconnect___nl"
  }, {
    path: "/autoconnect",
    component: _a94c3090,
    pathToRegexpOptions: {"strict":true},
    name: "autoconnect___de"
  }, {
    path: "/autoconnect",
    component: _a94c3090,
    pathToRegexpOptions: {"strict":true},
    name: "autoconnect___en"
  }, {
    path: "/brands",
    component: _49e91f8d,
    pathToRegexpOptions: {"strict":true},
    name: "marques___en"
  }, {
    path: "/c-(.+)-(\\d+)",
    component: _61ef379c,
    name: "artists___fr"
  }, {
    path: "/c-(.+)-(\\d+)",
    component: _61ef379c,
    name: "artists___es"
  }, {
    path: "/c-(.+)-(\\d+)",
    component: _61ef379c,
    name: "artists___it"
  }, {
    path: "/c-(.+)-(\\d+)",
    component: _61ef379c,
    name: "artists___nl"
  }, {
    path: "/c-(.+)-(\\d+)",
    component: _61ef379c,
    name: "artists___de"
  }, {
    path: "/c-(.+)-(\\d+)",
    component: _61ef379c,
    name: "artists___en"
  }, {
    path: "/contact",
    component: _d3903906,
    pathToRegexpOptions: {"strict":true},
    name: "contact___fr"
  }, {
    path: "/contact",
    component: _d3903906,
    pathToRegexpOptions: {"strict":true},
    name: "contact___es"
  }, {
    path: "/contact",
    component: _d3903906,
    pathToRegexpOptions: {"strict":true},
    name: "contact___it"
  }, {
    path: "/contact",
    component: _d3903906,
    pathToRegexpOptions: {"strict":true},
    name: "contact___nl"
  }, {
    path: "/contact",
    component: _d3903906,
    pathToRegexpOptions: {"strict":true},
    name: "contact___de"
  }, {
    path: "/contact",
    component: _d3903906,
    pathToRegexpOptions: {"strict":true},
    name: "contact___en"
  }, {
    path: "/login",
    component: _f7ef60f4,
    pathToRegexpOptions: {"strict":true},
    name: "login___fr"
  }, {
    path: "/login",
    component: _f7ef60f4,
    pathToRegexpOptions: {"strict":true},
    name: "login___es"
  }, {
    path: "/login",
    component: _f7ef60f4,
    pathToRegexpOptions: {"strict":true},
    name: "login___it"
  }, {
    path: "/login",
    component: _f7ef60f4,
    pathToRegexpOptions: {"strict":true},
    name: "login___nl"
  }, {
    path: "/login",
    component: _f7ef60f4,
    pathToRegexpOptions: {"strict":true},
    name: "login___de"
  }, {
    path: "/login",
    component: _f7ef60f4,
    pathToRegexpOptions: {"strict":true},
    name: "login___en"
  }, {
    path: "/maintenance",
    component: _10b20a8e,
    name: "maintenance___fr"
  }, {
    path: "/maintenance",
    component: _10b20a8e,
    name: "maintenance___es"
  }, {
    path: "/maintenance",
    component: _10b20a8e,
    name: "maintenance___it"
  }, {
    path: "/maintenance",
    component: _10b20a8e,
    name: "maintenance___nl"
  }, {
    path: "/maintenance",
    component: _10b20a8e,
    name: "maintenance___de"
  }, {
    path: "/maintenance",
    component: _10b20a8e,
    name: "maintenance___en"
  }, {
    path: "/marcas",
    component: _49e91f8d,
    pathToRegexpOptions: {"strict":true},
    name: "marques___es"
  }, {
    path: "/marche",
    component: _49e91f8d,
    pathToRegexpOptions: {"strict":true},
    name: "marques___it"
  }, {
    path: "/marken",
    component: _49e91f8d,
    pathToRegexpOptions: {"strict":true},
    name: "marques___de"
  }, {
    path: "/marques",
    component: _49e91f8d,
    pathToRegexpOptions: {"strict":true},
    name: "marques___fr"
  }, {
    path: "/merken",
    component: _49e91f8d,
    pathToRegexpOptions: {"strict":true},
    name: "marques___nl"
  }, {
    path: "/unsubscribe",
    component: _10fa3f2e,
    pathToRegexpOptions: {"strict":true},
    name: "unsubscribe___fr"
  }, {
    path: "/unsubscribe",
    component: _10fa3f2e,
    pathToRegexpOptions: {"strict":true},
    name: "unsubscribe___es"
  }, {
    path: "/unsubscribe",
    component: _10fa3f2e,
    pathToRegexpOptions: {"strict":true},
    name: "unsubscribe___it"
  }, {
    path: "/unsubscribe",
    component: _10fa3f2e,
    pathToRegexpOptions: {"strict":true},
    name: "unsubscribe___nl"
  }, {
    path: "/unsubscribe",
    component: _10fa3f2e,
    pathToRegexpOptions: {"strict":true},
    name: "unsubscribe___de"
  }, {
    path: "/unsubscribe",
    component: _10fa3f2e,
    pathToRegexpOptions: {"strict":true},
    name: "unsubscribe___en"
  }, {
    path: "/wishlist",
    component: _54cb0c90,
    pathToRegexpOptions: {"strict":true},
    name: "wishlist___fr"
  }, {
    path: "/wishlist",
    component: _54cb0c90,
    pathToRegexpOptions: {"strict":true},
    name: "wishlist___es"
  }, {
    path: "/wishlist",
    component: _54cb0c90,
    pathToRegexpOptions: {"strict":true},
    name: "wishlist___it"
  }, {
    path: "/wishlist",
    component: _54cb0c90,
    pathToRegexpOptions: {"strict":true},
    name: "wishlist___nl"
  }, {
    path: "/wishlist",
    component: _54cb0c90,
    pathToRegexpOptions: {"strict":true},
    name: "wishlist___de"
  }, {
    path: "/wishlist",
    component: _54cb0c90,
    pathToRegexpOptions: {"strict":true},
    name: "wishlist___en"
  }, {
    path: "/account/address-book",
    component: _4469620c,
    pathToRegexpOptions: {"strict":true},
    name: "account-address-book___fr"
  }, {
    path: "/account/address-book",
    component: _4469620c,
    pathToRegexpOptions: {"strict":true},
    name: "account-address-book___es"
  }, {
    path: "/account/address-book",
    component: _4469620c,
    pathToRegexpOptions: {"strict":true},
    name: "account-address-book___it"
  }, {
    path: "/account/address-book",
    component: _4469620c,
    pathToRegexpOptions: {"strict":true},
    name: "account-address-book___nl"
  }, {
    path: "/account/address-book",
    component: _4469620c,
    pathToRegexpOptions: {"strict":true},
    name: "account-address-book___de"
  }, {
    path: "/account/address-book",
    component: _4469620c,
    pathToRegexpOptions: {"strict":true},
    name: "account-address-book___en"
  }, {
    path: "/account/card-book",
    component: _703d3141,
    pathToRegexpOptions: {"strict":true},
    name: "account-card-book___fr"
  }, {
    path: "/account/card-book",
    component: _703d3141,
    pathToRegexpOptions: {"strict":true},
    name: "account-card-book___es"
  }, {
    path: "/account/card-book",
    component: _703d3141,
    pathToRegexpOptions: {"strict":true},
    name: "account-card-book___it"
  }, {
    path: "/account/card-book",
    component: _703d3141,
    pathToRegexpOptions: {"strict":true},
    name: "account-card-book___nl"
  }, {
    path: "/account/card-book",
    component: _703d3141,
    pathToRegexpOptions: {"strict":true},
    name: "account-card-book___de"
  }, {
    path: "/account/card-book",
    component: _703d3141,
    pathToRegexpOptions: {"strict":true},
    name: "account-card-book___en"
  }, {
    path: "/account/giftcard-activation",
    component: _43782f84,
    pathToRegexpOptions: {"strict":true},
    name: "account-giftcard-activation___fr"
  }, {
    path: "/account/giftcard-activation",
    component: _43782f84,
    pathToRegexpOptions: {"strict":true},
    name: "account-giftcard-activation___es"
  }, {
    path: "/account/giftcard-activation",
    component: _43782f84,
    pathToRegexpOptions: {"strict":true},
    name: "account-giftcard-activation___it"
  }, {
    path: "/account/giftcard-activation",
    component: _43782f84,
    pathToRegexpOptions: {"strict":true},
    name: "account-giftcard-activation___nl"
  }, {
    path: "/account/giftcard-activation",
    component: _43782f84,
    pathToRegexpOptions: {"strict":true},
    name: "account-giftcard-activation___de"
  }, {
    path: "/account/giftcard-activation",
    component: _43782f84,
    pathToRegexpOptions: {"strict":true},
    name: "account-giftcard-activation___en"
  }, {
    path: "/account/history",
    component: _e776815c,
    pathToRegexpOptions: {"strict":true},
    name: "account-history___fr"
  }, {
    path: "/account/history",
    component: _e776815c,
    pathToRegexpOptions: {"strict":true},
    name: "account-history___es"
  }, {
    path: "/account/history",
    component: _e776815c,
    pathToRegexpOptions: {"strict":true},
    name: "account-history___it"
  }, {
    path: "/account/history",
    component: _e776815c,
    pathToRegexpOptions: {"strict":true},
    name: "account-history___nl"
  }, {
    path: "/account/history",
    component: _e776815c,
    pathToRegexpOptions: {"strict":true},
    name: "account-history___de"
  }, {
    path: "/account/history",
    component: _e776815c,
    pathToRegexpOptions: {"strict":true},
    name: "account-history___en"
  }, {
    path: "/account/modify",
    component: _5703430f,
    pathToRegexpOptions: {"strict":true},
    name: "account-modify___fr"
  }, {
    path: "/account/modify",
    component: _5703430f,
    pathToRegexpOptions: {"strict":true},
    name: "account-modify___es"
  }, {
    path: "/account/modify",
    component: _5703430f,
    pathToRegexpOptions: {"strict":true},
    name: "account-modify___it"
  }, {
    path: "/account/modify",
    component: _5703430f,
    pathToRegexpOptions: {"strict":true},
    name: "account-modify___nl"
  }, {
    path: "/account/modify",
    component: _5703430f,
    pathToRegexpOptions: {"strict":true},
    name: "account-modify___de"
  }, {
    path: "/account/modify",
    component: _5703430f,
    pathToRegexpOptions: {"strict":true},
    name: "account-modify___en"
  }, {
    path: "/account/modify-id",
    component: _787cacc9,
    pathToRegexpOptions: {"strict":true},
    name: "account-modify-id___fr"
  }, {
    path: "/account/modify-id",
    component: _787cacc9,
    pathToRegexpOptions: {"strict":true},
    name: "account-modify-id___es"
  }, {
    path: "/account/modify-id",
    component: _787cacc9,
    pathToRegexpOptions: {"strict":true},
    name: "account-modify-id___it"
  }, {
    path: "/account/modify-id",
    component: _787cacc9,
    pathToRegexpOptions: {"strict":true},
    name: "account-modify-id___nl"
  }, {
    path: "/account/modify-id",
    component: _787cacc9,
    pathToRegexpOptions: {"strict":true},
    name: "account-modify-id___de"
  }, {
    path: "/account/modify-id",
    component: _787cacc9,
    pathToRegexpOptions: {"strict":true},
    name: "account-modify-id___en"
  }, {
    path: "/account/subscriptions",
    component: _189aa19e,
    pathToRegexpOptions: {"strict":true},
    name: "account-subscriptions___fr"
  }, {
    path: "/account/subscriptions",
    component: _189aa19e,
    pathToRegexpOptions: {"strict":true},
    name: "account-subscriptions___es"
  }, {
    path: "/account/subscriptions",
    component: _189aa19e,
    pathToRegexpOptions: {"strict":true},
    name: "account-subscriptions___it"
  }, {
    path: "/account/subscriptions",
    component: _189aa19e,
    pathToRegexpOptions: {"strict":true},
    name: "account-subscriptions___nl"
  }, {
    path: "/account/subscriptions",
    component: _189aa19e,
    pathToRegexpOptions: {"strict":true},
    name: "account-subscriptions___de"
  }, {
    path: "/account/subscriptions",
    component: _189aa19e,
    pathToRegexpOptions: {"strict":true},
    name: "account-subscriptions___en"
  }, {
    path: "/account/voucher",
    component: _79fccd29,
    pathToRegexpOptions: {"strict":true},
    name: "account-voucher___fr"
  }, {
    path: "/account/voucher",
    component: _79fccd29,
    pathToRegexpOptions: {"strict":true},
    name: "account-voucher___es"
  }, {
    path: "/account/voucher",
    component: _79fccd29,
    pathToRegexpOptions: {"strict":true},
    name: "account-voucher___it"
  }, {
    path: "/account/voucher",
    component: _79fccd29,
    pathToRegexpOptions: {"strict":true},
    name: "account-voucher___nl"
  }, {
    path: "/account/voucher",
    component: _79fccd29,
    pathToRegexpOptions: {"strict":true},
    name: "account-voucher___de"
  }, {
    path: "/account/voucher",
    component: _79fccd29,
    pathToRegexpOptions: {"strict":true},
    name: "account-voucher___en"
  }, {
    path: "/checkout/add_address",
    component: _d2572b00,
    pathToRegexpOptions: {"strict":true},
    name: "checkout-add_address___fr"
  }, {
    path: "/checkout/add_address",
    component: _d2572b00,
    pathToRegexpOptions: {"strict":true},
    name: "checkout-add_address___es"
  }, {
    path: "/checkout/add_address",
    component: _d2572b00,
    pathToRegexpOptions: {"strict":true},
    name: "checkout-add_address___it"
  }, {
    path: "/checkout/add_address",
    component: _d2572b00,
    pathToRegexpOptions: {"strict":true},
    name: "checkout-add_address___nl"
  }, {
    path: "/checkout/add_address",
    component: _d2572b00,
    pathToRegexpOptions: {"strict":true},
    name: "checkout-add_address___de"
  }, {
    path: "/checkout/add_address",
    component: _d2572b00,
    pathToRegexpOptions: {"strict":true},
    name: "checkout-add_address___en"
  }, {
    path: "/checkout/basket",
    component: _2fb93d8c,
    pathToRegexpOptions: {"strict":true},
    name: "checkout-basket___fr"
  }, {
    path: "/checkout/basket",
    component: _2fb93d8c,
    pathToRegexpOptions: {"strict":true},
    name: "checkout-basket___es"
  }, {
    path: "/checkout/basket",
    component: _2fb93d8c,
    pathToRegexpOptions: {"strict":true},
    name: "checkout-basket___it"
  }, {
    path: "/checkout/basket",
    component: _2fb93d8c,
    pathToRegexpOptions: {"strict":true},
    name: "checkout-basket___nl"
  }, {
    path: "/checkout/basket",
    component: _2fb93d8c,
    pathToRegexpOptions: {"strict":true},
    name: "checkout-basket___de"
  }, {
    path: "/checkout/basket",
    component: _2fb93d8c,
    pathToRegexpOptions: {"strict":true},
    name: "checkout-basket___en"
  }, {
    path: "/checkout/confirm",
    component: _c1bcca2c,
    pathToRegexpOptions: {"strict":true},
    name: "checkout-confirm___fr"
  }, {
    path: "/checkout/confirm",
    component: _c1bcca2c,
    pathToRegexpOptions: {"strict":true},
    name: "checkout-confirm___es"
  }, {
    path: "/checkout/confirm",
    component: _c1bcca2c,
    pathToRegexpOptions: {"strict":true},
    name: "checkout-confirm___it"
  }, {
    path: "/checkout/confirm",
    component: _c1bcca2c,
    pathToRegexpOptions: {"strict":true},
    name: "checkout-confirm___nl"
  }, {
    path: "/checkout/confirm",
    component: _c1bcca2c,
    pathToRegexpOptions: {"strict":true},
    name: "checkout-confirm___de"
  }, {
    path: "/checkout/confirm",
    component: _c1bcca2c,
    pathToRegexpOptions: {"strict":true},
    name: "checkout-confirm___en"
  }, {
    path: "/checkout/payment",
    component: _2ec9d330,
    pathToRegexpOptions: {"strict":true},
    name: "checkout-payment___fr"
  }, {
    path: "/checkout/payment",
    component: _2ec9d330,
    pathToRegexpOptions: {"strict":true},
    name: "checkout-payment___es"
  }, {
    path: "/checkout/payment",
    component: _2ec9d330,
    pathToRegexpOptions: {"strict":true},
    name: "checkout-payment___it"
  }, {
    path: "/checkout/payment",
    component: _2ec9d330,
    pathToRegexpOptions: {"strict":true},
    name: "checkout-payment___nl"
  }, {
    path: "/checkout/payment",
    component: _2ec9d330,
    pathToRegexpOptions: {"strict":true},
    name: "checkout-payment___de"
  }, {
    path: "/checkout/payment",
    component: _2ec9d330,
    pathToRegexpOptions: {"strict":true},
    name: "checkout-payment___en"
  }, {
    path: "/checkout/shipping",
    component: _9a204c18,
    pathToRegexpOptions: {"strict":true},
    name: "checkout-shipping___fr"
  }, {
    path: "/checkout/shipping",
    component: _9a204c18,
    pathToRegexpOptions: {"strict":true},
    name: "checkout-shipping___es"
  }, {
    path: "/checkout/shipping",
    component: _9a204c18,
    pathToRegexpOptions: {"strict":true},
    name: "checkout-shipping___it"
  }, {
    path: "/checkout/shipping",
    component: _9a204c18,
    pathToRegexpOptions: {"strict":true},
    name: "checkout-shipping___nl"
  }, {
    path: "/checkout/shipping",
    component: _9a204c18,
    pathToRegexpOptions: {"strict":true},
    name: "checkout-shipping___de"
  }, {
    path: "/checkout/shipping",
    component: _9a204c18,
    pathToRegexpOptions: {"strict":true},
    name: "checkout-shipping___en"
  }, {
    path: "/",
    component: _8b3ea722,
    pathToRegexpOptions: {"strict":true},
    name: "index___fr"
  }, {
    path: "/",
    component: _8b3ea722,
    pathToRegexpOptions: {"strict":true},
    name: "index___es"
  }, {
    path: "/",
    component: _8b3ea722,
    pathToRegexpOptions: {"strict":true},
    name: "index___it"
  }, {
    path: "/",
    component: _8b3ea722,
    pathToRegexpOptions: {"strict":true},
    name: "index___nl"
  }, {
    path: "/",
    component: _8b3ea722,
    pathToRegexpOptions: {"strict":true},
    name: "index___de"
  }, {
    path: "/",
    component: _8b3ea722,
    pathToRegexpOptions: {"strict":true},
    name: "index___en"
  }, {
    path: "/account/address-book/:id?",
    component: _5016703c,
    pathToRegexpOptions: {"strict":true},
    name: "account-address-book-id___fr"
  }, {
    path: "/account/address-book/:id?",
    component: _5016703c,
    pathToRegexpOptions: {"strict":true},
    name: "account-address-book-id___es"
  }, {
    path: "/account/address-book/:id?",
    component: _5016703c,
    pathToRegexpOptions: {"strict":true},
    name: "account-address-book-id___it"
  }, {
    path: "/account/address-book/:id?",
    component: _5016703c,
    pathToRegexpOptions: {"strict":true},
    name: "account-address-book-id___nl"
  }, {
    path: "/account/address-book/:id?",
    component: _5016703c,
    pathToRegexpOptions: {"strict":true},
    name: "account-address-book-id___de"
  }, {
    path: "/account/address-book/:id?",
    component: _5016703c,
    pathToRegexpOptions: {"strict":true},
    name: "account-address-book-id___en"
  }, {
    path: "/account/history/:id",
    component: _6dac3d86,
    pathToRegexpOptions: {"strict":true},
    name: "account-history-id___fr"
  }, {
    path: "/account/history/:id",
    component: _6dac3d86,
    pathToRegexpOptions: {"strict":true},
    name: "account-history-id___es"
  }, {
    path: "/account/history/:id",
    component: _6dac3d86,
    pathToRegexpOptions: {"strict":true},
    name: "account-history-id___it"
  }, {
    path: "/account/history/:id",
    component: _6dac3d86,
    pathToRegexpOptions: {"strict":true},
    name: "account-history-id___nl"
  }, {
    path: "/account/history/:id",
    component: _6dac3d86,
    pathToRegexpOptions: {"strict":true},
    name: "account-history-id___de"
  }, {
    path: "/account/history/:id",
    component: _6dac3d86,
    pathToRegexpOptions: {"strict":true},
    name: "account-history-id___en"
  }, {
    path: "/account/history/:id/product-return-detail",
    component: _bfc28e44,
    pathToRegexpOptions: {"strict":true},
    name: "account-history-id-product-return-detail___fr"
  }, {
    path: "/account/history/:id/product-return-detail",
    component: _bfc28e44,
    pathToRegexpOptions: {"strict":true},
    name: "account-history-id-product-return-detail___es"
  }, {
    path: "/account/history/:id/product-return-detail",
    component: _bfc28e44,
    pathToRegexpOptions: {"strict":true},
    name: "account-history-id-product-return-detail___it"
  }, {
    path: "/account/history/:id/product-return-detail",
    component: _bfc28e44,
    pathToRegexpOptions: {"strict":true},
    name: "account-history-id-product-return-detail___nl"
  }, {
    path: "/account/history/:id/product-return-detail",
    component: _bfc28e44,
    pathToRegexpOptions: {"strict":true},
    name: "account-history-id-product-return-detail___de"
  }, {
    path: "/account/history/:id/product-return-detail",
    component: _bfc28e44,
    pathToRegexpOptions: {"strict":true},
    name: "account-history-id-product-return-detail___en"
  }, {
    path: "/account/history/:id/product-return-detail/:idreturn?",
    component: _a923b514,
    pathToRegexpOptions: {"strict":true},
    name: "account-history-id-product-return-detail-idreturn___fr"
  }, {
    path: "/account/history/:id/product-return-detail/:idreturn?",
    component: _a923b514,
    pathToRegexpOptions: {"strict":true},
    name: "account-history-id-product-return-detail-idreturn___es"
  }, {
    path: "/account/history/:id/product-return-detail/:idreturn?",
    component: _a923b514,
    pathToRegexpOptions: {"strict":true},
    name: "account-history-id-product-return-detail-idreturn___it"
  }, {
    path: "/account/history/:id/product-return-detail/:idreturn?",
    component: _a923b514,
    pathToRegexpOptions: {"strict":true},
    name: "account-history-id-product-return-detail-idreturn___nl"
  }, {
    path: "/account/history/:id/product-return-detail/:idreturn?",
    component: _a923b514,
    pathToRegexpOptions: {"strict":true},
    name: "account-history-id-product-return-detail-idreturn___de"
  }, {
    path: "/account/history/:id/product-return-detail/:idreturn?",
    component: _a923b514,
    pathToRegexpOptions: {"strict":true},
    name: "account-history-id-product-return-detail-idreturn___en"
  }, {
    path: "/autoconnect/:token",
    component: _a94c3090
  }, {
    path: "/autoconnect/:token",
    component: _a94c3090
  }, {
    path: "/autoconnect/:token",
    component: _a94c3090
  }, {
    path: "/autoconnect/:token",
    component: _a94c3090
  }, {
    path: "/autoconnect/:token",
    component: _a94c3090
  }, {
    path: "/autoconnect/:token",
    component: _a94c3090
  }, {
    path: "/search/:query?",
    component: _3ad2a9ad,
    pathToRegexpOptions: {"strict":true},
    name: "search-query___fr"
  }, {
    path: "/search/:query?",
    component: _3ad2a9ad,
    pathToRegexpOptions: {"strict":true},
    name: "search-query___es"
  }, {
    path: "/search/:query?",
    component: _3ad2a9ad,
    pathToRegexpOptions: {"strict":true},
    name: "search-query___it"
  }, {
    path: "/search/:query?",
    component: _3ad2a9ad,
    pathToRegexpOptions: {"strict":true},
    name: "search-query___nl"
  }, {
    path: "/search/:query?",
    component: _3ad2a9ad,
    pathToRegexpOptions: {"strict":true},
    name: "search-query___de"
  }, {
    path: "/search/:query?",
    component: _3ad2a9ad,
    pathToRegexpOptions: {"strict":true},
    name: "search-query___en"
  }, {
    path: "/t/:name?",
    component: _57ff01ec,
    pathToRegexpOptions: {"strict":true},
    name: "t-name___fr"
  }, {
    path: "/t/:name?",
    component: _57ff01ec,
    pathToRegexpOptions: {"strict":true},
    name: "t-name___es"
  }, {
    path: "/t/:name?",
    component: _57ff01ec,
    pathToRegexpOptions: {"strict":true},
    name: "t-name___it"
  }, {
    path: "/t/:name?",
    component: _57ff01ec,
    pathToRegexpOptions: {"strict":true},
    name: "t-name___nl"
  }, {
    path: "/t/:name?",
    component: _57ff01ec,
    pathToRegexpOptions: {"strict":true},
    name: "t-name___de"
  }, {
    path: "/t/:name?",
    component: _57ff01ec,
    pathToRegexpOptions: {"strict":true},
    name: "t-name___en"
  }, {
    path: "/tp/:name?",
    component: _5746f5ec,
    pathToRegexpOptions: {"strict":true},
    name: "tp-name___fr"
  }, {
    path: "/tp/:name?",
    component: _5746f5ec,
    pathToRegexpOptions: {"strict":true},
    name: "tp-name___es"
  }, {
    path: "/tp/:name?",
    component: _5746f5ec,
    pathToRegexpOptions: {"strict":true},
    name: "tp-name___it"
  }, {
    path: "/tp/:name?",
    component: _5746f5ec,
    pathToRegexpOptions: {"strict":true},
    name: "tp-name___nl"
  }, {
    path: "/tp/:name?",
    component: _5746f5ec,
    pathToRegexpOptions: {"strict":true},
    name: "tp-name___de"
  }, {
    path: "/tp/:name?",
    component: _5746f5ec,
    pathToRegexpOptions: {"strict":true},
    name: "tp-name___en"
  }, {
    path: "/search/:query/*",
    component: _3ad2a9ad,
    name: "search___en"
  }, {
    path: "/search/:query/*",
    component: _3ad2a9ad,
    name: "search___de"
  }, {
    path: "/search/:query/*",
    component: _3ad2a9ad,
    name: "search___nl"
  }, {
    path: "/search/:query/*",
    component: _3ad2a9ad,
    name: "search___it"
  }, {
    path: "/search/:query/*",
    component: _3ad2a9ad,
    name: "search___es"
  }, {
    path: "/search/:query/*",
    component: _3ad2a9ad,
    name: "search___fr"
  }, {
    path: "/p/*",
    component: _25c7645d,
    pathToRegexpOptions: {"strict":true},
    name: "p-all___en"
  }, {
    path: "/p/*",
    component: _25c7645d,
    pathToRegexpOptions: {"strict":true},
    name: "p-all___de"
  }, {
    path: "/p/*",
    component: _25c7645d,
    pathToRegexpOptions: {"strict":true},
    name: "p-all___nl"
  }, {
    path: "/p/*",
    component: _25c7645d,
    pathToRegexpOptions: {"strict":true},
    name: "p-all___it"
  }, {
    path: "/p/*",
    component: _25c7645d,
    pathToRegexpOptions: {"strict":true},
    name: "p-all___es"
  }, {
    path: "/p/*",
    component: _25c7645d,
    pathToRegexpOptions: {"strict":true},
    name: "p-all___fr"
  }, {
    path: "/*",
    component: _73468008,
    pathToRegexpOptions: {"strict":true},
    name: "all___en"
  }, {
    path: "/*",
    component: _73468008,
    pathToRegexpOptions: {"strict":true},
    name: "all___de"
  }, {
    path: "/*",
    component: _73468008,
    pathToRegexpOptions: {"strict":true},
    name: "all___nl"
  }, {
    path: "/*",
    component: _73468008,
    pathToRegexpOptions: {"strict":true},
    name: "all___it"
  }, {
    path: "/*",
    component: _73468008,
    pathToRegexpOptions: {"strict":true},
    name: "all___es"
  }, {
    path: "/*",
    component: _73468008,
    pathToRegexpOptions: {"strict":true},
    name: "all___fr"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
